define(['jquery'], function ($) {
    $.fn.fixedSidebar = function (options) {
        this.each(function (index, element) {
            return new FixedSidebar(element, options);
        });
    };

    function FixedSidebar(el, options) {
        var $element = $(el);

        function update() {
            var pageOffset = (typeof window.pageYOffset === 'undefined' ?
                    document.documentElement.scrollTop :
                    window.pageYOffset),
                bottomOffset = $element.outerHeight() + options.bottom,
                bottomLine = $(document).height() - bottomOffset,
                isOnTop = pageOffset < options.top,
                isAtBottom = pageOffset > bottomLine;

            $element
                .toggleClass('top', isOnTop)
                .toggleClass('end', !isOnTop && isAtBottom)
                .toggleClass('floating', !isOnTop);
        }

        $(window).on('scroll', update);

        update();
    }
});
