var SETTINGS = require('airborne/settings');
var features = require('airborne/features');

require('fixed_sidebar');
require('../less/bootstrap.less');

global.airborne = {
    settings: SETTINGS.default,
    features: features.default,
};
